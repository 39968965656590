.main-content {
    .hero {
      text-align: center;
      padding: 50px 20px;
    }
  
    .features {
      background-color: #f8f9fa;
      padding: 20px;
      .feature-list {
        display: flex;
        justify-content: center;
        .feature {
          margin: 0 15px;
        }
      }
    }
  }