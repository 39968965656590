.footer {
    background-color: #343a40;
    color: #ffffff;
    text-align: center;
    padding: 20px;
    .social-links {
      margin-top: 10px;
      a {
        color: #ffffff;
        margin: 0 10px;
      }
    }
  }